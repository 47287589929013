// import { DS_F2W_HR_Team } from 'Constants/F2wRolesConstants';
// import { DS_F2W_Edit } from 'Constants/F2wRolesConstants';
// import { DS_F2W_Business_Travel_Team } from 'Constants/F2wRolesConstants';
import { GET_USER_SUCCESS } from '../../../Constants/UserConstants';

var defaultState = {
  given_name: '',
  family_name: '',
  upn: '',
  roles: [],
};

export default function currentUserReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_USER_SUCCESS:
      // console.log('action.data.user', action.data.user);
      return {
        ...action.data.user,
        // roles: [DS_F2W_Edit, DS_F2W_HR_Team, DS_F2W_Business_Travel_Team],
      };
    default:
      return state;
  }
}
